* {
  margin: 0;
}
.navbar {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 50px;
  }

  .logo {
    display: flex;
    align-items: center;
  }

  .logo a {
    text-decoration: none;
    padding-left: 5px;
    color: #fff;
    font-weight: 700;

  }

  .logo img {
    height: 40px;
  }

  .buttons {
    display: flex;

  }

  .buttons button {
    cursor: pointer;
  }
  /* .buttons #normal {
    opacity: 50%;
    background-color: transparent;
    cursor: no-drop;
  } */

  .button {
    padding: 10px 16px;
    background-color: transparent;
    border: none;
    border-radius: 15px;
    font-size: 15px;
    color: #fff;
    font-weight: 480;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none;
    transition: all .9s;

  }

  .buttons button {
    background-color: transparent;
    border: none;
    border-radius: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 450;
    text-decoration: none;
    transition: all .9s;

  }

  .button:hover {
    transition: all .9s;
    background-color:  #1f281f;
  }




  .InicioHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;               
  }

  .InicioHeader img {
    height: 200px;
  }

  .InicioHeader .text {
    color: #fff;
    text-align: left;
    padding-left: 80px;
  }
  .InicioHeader h1 {
    font-size: 50px;
  }
  .InicioHeader a {
    font-weight: 500;
  }

  .InicioHeader button {
    border: 2px transparent;
    border-radius: 50px;
    background-color: transparent;
    margin: 5px;
    margin-top: 15px;
    padding: 12px 20px;
    font-weight: 900px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
  }
  .InicioHeader button a {
   font-weight: 600;
  }
  
  .InicioHeader .b1 {
    background-color: #317f31;
  }
  .InicioHeader .b1:hover {
    background-color: #257325;
  }
  .InicioHeader .b2 {
    border:2px solid #2b382b;
  }
  .InicioHeader .b2:hover {
    background-color: #2b382b20;
  }
  .InicioHeader .b2:active {
    background-color: #2b382b41;
  }