.Anuncios {
    height: auto;
}
.Anuncios .texto {
    text-align: center;
}
.Anuncios .texto h1 {
    color: #fff;
}
.Anuncios .texto h4 {
    color: #5dc32e;
}


.Anuncios .cajas {
    padding: 40px;
}



.Anuncios .caja {
    background-color: #202b20;
    border-radius: 20px;
    padding: 20px;
    text-align: left;
    color: #fff;
    border: 0;
}

.Anuncios .caja {
    cursor: pointer;
}

.Anuncios .caja .Titulo {
    font-size: 18px;
    font-weight: 700;
}
.Anuncios .caja .Descripcion {
    font-size: 15px;
    opacity: 60%;
}

.Anuncios .caja i {
    margin-left: 10px;

    background-color: #1a231a;
    border-radius: 100px;
    transition: all .4s;
    padding: 10px;
}

.caja:hover i {
    background-color: #141814;
    transition: all .4s;
}