.Anuncios {
    margin: 0;
    padding: 0;
    color: #fff;
}

.Anuncios .Usuario a{
    padding: 20px

}

.Anuncios h1 {
    color: aliceblue;
    text-align: center;
}

.Anuncios .Usuario {
    padding: 30px;
}

.Anuncios .Usuarios {
    display: flex;
    padding-top: 10px;
}

.Anuncios .Usuarios img {
    height: 50px;
    margin: 0 20px;
    border-radius: 50px;
}

.Anuncios .Usuarios .nombre{
    font-weight: 700;
}
.Anuncios .Usuarios .role{
    opacity: 60%;
}

.Anuncios .AnuncioContent {
    padding: 50px 100px 150px 100px;
}

.Anuncios .AnuncioContent button {
    background-color: #202b20;
    color: #fff;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 600;
    font-size: 16px;
    padding: 12px 30px;
    border-radius: 20px;
    border: 0;
    transition: all .5s

    
}

.Anuncios .AnuncioContent button:hover {
    background-color: #202b20b9;
    transition: all .5s
}

.Anuncios .AnuncioContent i {
    padding-left: 15px;
}