body {
  background-color: #141a14;
}
.App {
  text-align: center;
}

.SpanVerde {
  color: rgb(19, 179, 35);
  text-decoration: dashed;
}
.SpanVerde1 {
  color: rgba(59, 184, 72, 0.808);
  text-decoration: dashed;
}

.x10 {
  font-size: 10px;
}

.x15 {
  font-size: 15px;
}

.x18 {
  font-size: 18px;
}

.x20 {
  font-size: 20px;
}

.x25 {
  font-size: 25px;
}

.x30 {
  font-size: 30px;
}

.o60 {
  opacity: 60%;
}
.o80 {
  opacity: 80%;
}
.o90 {
  opacity: 90%;
}